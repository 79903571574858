import configData from './config.json';

const API_BASE = process.env.REACT_APP_API_URL + (configData.ENVIRONMENT === "alpha" ? "/alpha" : "");

export function app_fetch(
        apiPath,
        requestOptions = {method : "GET", headers : {}, mode : 'cors'},
        success = (data) => {return data},
        error = (err) => {},
        final = () => {}
    ){
    requestOptions['headers'] = {...requestOptions['headers'], 'X-Token' : process.env.REACT_APP_X_TOKEN };
    let apiUrl = API_BASE + apiPath;
    fetch(apiUrl, requestOptions)
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error('Network response was not ok');
            }
        }).then(
            (data) => {return success(data)}
        ).catch(
            (err) => {error(err)}
        ).finally(
            () => {final()}
        )
}

export function lowestAvailableNum(playerList) {
    const numList = playerList.map((player) => (player.numero));
    let num = 1;
    while (numList.includes(num)) {
        num++;
    }
    return num;
}

export function sortPlayers(players) {
    return players.sort((a, b) => {
        const numA = a.nom ? a.numero : a.numero + 10000;
        const numB = b.nom ? b.numero : b.numero + 10000;
        return numA - numB;
    });
}
